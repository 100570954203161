<template>
	<div class="retrieve">
		<div class="box-top"></div>
		<el-button type="text" 
			icon="el-icon-d-arrow-left" 
			class="btn-return"
			@click="handleReturn"
		>返回登录</el-button>
		<div class="box-center">
			<div class="box-logo">
				<img src="../assets/mask/login/logo.png" style="width: 119px; height: 118px" />
			</div>
			<div class="content">
				<div style="text-align: center;color: #1577CB;">
					密码找回
					<div class="box-title"></div>
				</div>

        <el-form ref="smsLogin" :model="smsLogin" :rules="loginRules" label-width="0">
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" v-model="smsLogin.phone" clearable>
              <img slot="prefix" 
                src="../assets/mask/login/sj.png" 
                style="width: 22px; height: 22px;padding-top: 10px;" />
            </el-input>
          </el-form-item>
          <el-form-item prop="value" class="sms">
            <el-input placeholder="请输入验证码" v-model="smsLogin.value">
              <img slot="prefix"
                src="../assets/mask/login/yzm.png" 
                style="width: 22px; height: 22px;padding-top: 10px;" />
              <el-button 
                slot="suffix" 
                type="primary" 
                @click="sendSMS" 
                :disabled="btnDisabled"
                size="mini">
                {{!btnDisabled ? '获取验证码' : countLoading + '秒后可重发'}}
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
	
				<div style="padding-top: 10px;">
					<el-button type="warning" @click="handleStep('smsLogin')">下一步</el-button>
				</div>
			</div>
			<div class="floor">
				Copyright ©2022-2025  Mask版权所有
			</div>
		</div>
		<el-dialog
      title="设置新密码"
      :visible.sync="dialogVisible"
      width="480px"
      :before-close="handleClose">
      <el-form 
        ref="formInfo" 
        :model="formInfo" 
        :rules="loginRules" 
        class="formInfo" 
        label-width="100px"
      >
				<el-form-item label="新密码" prop="password">
					<el-input 
            v-model="formInfo.password" 
            placeholder="请输入新密码"
            show-password
          ></el-input>
				</el-form-item>
				<el-form-item label="确认新密码" prop="affirmPassword">
					<el-input 
            v-model="formInfo.affirmPassword" 
            placeholder="请再次输入新密码"
            show-password
          ></el-input>
				</el-form-item>
			</el-form>
			
      <div slot="footer" class="retrieve-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave('formInfo')">保存</el-button>
      </div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { isValidMobileNumber } from "../utils";
import {smsSend, temporaryToken, tokenChangePassword} from "../api";
	export default {
    name: 'retrieve',
    data() {
			return {
        countLoading: 60,
        btnDisabled: false,
        smsLogin: {
          phone: '',
          value: '',
          id: '',
        },
        loginRules: {
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"}
          ],
          value: [
            {required: true, message: "请输入验证码", trigger: "blur"}
          ],
          password: [
            {required: true, message: "请输入新密码", trigger: "blur"}
          ],
          affirmPassword: [
            {required: true, message: "请再次输入新密码", trigger: "blur"}
          ],
        },
				dialogVisible: false,
				formInfo: {
					password: '',
          affirmPassword: ''
				}
			}
		},
		methods: {
      ...mapActions(['updateUserInfo']),
      sendSMS(){
        if (!isValidMobileNumber(this.smsLogin.phone)) {
          this.$message.error('请输入正确手机号');
          return;
        }
        this.btnDisabled = true;
        this.countLoading = 60;
        var time = setInterval(() => {
          this.countLoading--;
          if(this.countLoading <= 0){
            this.btnDisabled = false;
            clearInterval(time);
          }
        },1000);
        smsSend({phone: this.smsLogin.phone}).then(({ data, code }) => {
          if (code === 200) {
            this.smsLogin.id = data.id;
          }
        })
        .catch(() => {
          this.$message.error('发送短信失败');
        });
      },
			handleStep(smsLogin) {
        this.$refs[smsLogin].validate((valid) => {
          if (valid) {
            temporaryToken(this.smsLogin).then((res) => {
              const data = { TemporaryToken: res.data };
              this.updateUserInfo(data);
              this.dialogVisible = true;
            });
          } else {
            return ;
          }
        });
			},
      handleSave(formInfo) {
        this.$refs[formInfo].validate((valid) => {
          if (valid) {
            if(this.formInfo.password !== this.formInfo.affirmPassword) {
              this.$message.error('两次输入的密码不一致');
              return;
            }
            const encrypt = this.$sha1(this.$md5(this.formInfo.password));
            tokenChangePassword({password: encrypt}).then((res) => {
              this.dialogVisible = false;
              this.$message.success('密码修改成功');
              this.handleReturn();
            });
          } else {
            return ;
          }
        });
      },
			handleClose() {
				this.dialogVisible = false;
			},
			handleReturn() {
				this.$router.go(-1);
			},
		}
	}
</script>

<style lang="less">
	.retrieve{
		.box-top{
			width: 100%;
			height: 440px;
			background: #444A59;
			position: absolute;
			z-index: -1;
		}
		.btn-return{
			margin-top: 20px;
			margin-left: 20px;
			color: #fff;
			font-size: 16px;
		}
		.box-center{
			width: 430px;
			margin: 0 auto;
			
			.box-logo{
				padding-top: 60px;
				padding-bottom: 35px;
				text-align: center;
			}
		}
		.content{
			width: 332px;
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 49px;
			
			.box-title{
				height: 3px;
				width: 30px;
				background: #DAB87A;
				margin: 0 auto;
				margin-top: 8px;
				margin-bottom: 30px;
			}
			
			.el-input__inner:hover,
			.el-input__inner{
				border-radius: 0;
				border: 1px solid #fff;
				border-bottom: 1px solid #E6E6E6;
			}
      .el-input--prefix .el-input__inner {
				height: 45px;
				padding-left: 40px;
			}
			
			.el-button--warning:focus, 
			.el-button--warning:hover,
			.el-button--warning {
				background-color: #DAB87A;
				border-color: #DAB87A;
				width: 100%;
				margin-bottom: 25px;
				font-size: 18px;
			}
      .el-button--primary:focus,
			.el-button--primary:hover,
			.el-button--primary{
				background-color: #1577CB;
				border-color: #1577CB;
				width: 100%;
				margin-bottom: 25px;
				font-size: 12px;
			}
		}
		.floor{
			font-size: 14px;
			color: #C8C8C8;
			padding-left: 87px;
      text-align: center;
			position: absolute;
			bottom: 10px;
			z-index: -1;
		}
		
		.formInfo{
			padding-right: 30px;
			.el-input__inner:hover,
			.el-input__inner{
				border-radius: 0;
				border: 1px solid #fff;
				border-bottom: 1px solid #E6E6E6;
			}
			.el-input--prefix .el-input__inner {
				height: 35px;
				padding-left: 40px;
			}
			.el-form-item__label{
				color: #1577CB;
			}
		}
		
		.el-dialog__footer{
			padding: 0;
			text-align: left;
		}
		.el-dialog__wrapper{
			top: 180px;
		}
		.retrieve-footer{
			.el-button{
				width: 240px;
				border-radius: 0;
			}
			.el-button+.el-button{
				margin-left: 0;
			}
			.el-button--default{
				background: #DAB87A;
				border: 1px solid #DAB87A;
				color: #fff;
			}
			.el-button--primary{
				color: #FFF;
				background-color: #1577CB;
				border-color: #1577CB;
			}
		}
	}
</style>
